import React, { useCallback } from 'react';
import Lottie from 'react-lottie';
import logo from '../../assets/animation.json';

import { useTheme } from '../../hooks/theme';

import { HexToRgb } from '../../utils/Colors';

import Button from '../../components/Button';
import Particles from '../../components/Particles/index';

import { Container, Hero, Footer } from './styles';

const Main: React.FC = () => {
  const { getTheme } = useTheme();
  const palette = getTheme();

  const colorAnimation = useCallback(() => {
    const coloredLogo = { ...logo };

    coloredLogo.layers.forEach((layer: any) => {
      const color = HexToRgb(palette.primary);

      if (color) {
        // eslint-disable-next-line no-param-reassign
        layer.shapes[0].it[1].c.k = [
          color.r / 255,
          color.g / 255,
          color.b / 255,
          1,
        ];
      }
    });

    return coloredLogo;
  }, [palette]);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: colorAnimation(),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container palette={palette} className="container">
      <Button />
      <Hero palette={palette} className="hero">
        <Lottie options={defaultOptions} height="auto" width="100%" />

        <span>
          Solicite um orçamento!{' '}
          <a href="mailto:contato@nbbula.com">contato@nbbula.com</a>
        </span>
      </Hero>

      <Particles />

      <Footer palette={palette}>
        <span>Criação de páginas e websites únicos e personalizados</span>
        <strong>Faça parte da nossa comunidade!</strong>
      </Footer>
    </Container>
  );
};

export default Main;
