export default [
  {
    type: 'default',
    background: '#ffffff',
    primary: '#000000',
    secondary: '#ffCB05',
  },
  {
    type: 'default',
    background: '#ffffff',
    primary: '#55cb24',
    secondary: '#4e00a8',
  },
  {
    type: 'default',
    background: '#f2dbd5',
    primary: '#F25050',
    secondary: '#112A40',
  },
  {
    type: 'default',
    background: '#cadc9f',
    primary: '#0f380f',
    secondary: '#8bac0f',
  },
  {
    type: 'default',
    background: '#282a36',
    primary: '#50fa7b',
    secondary: '#44475a',
  },
  {
    type: 'default',
    background: '#282a36',
    primary: '#ff79c6',
    secondary: '#44475a',
  },
  {
    type: 'default',
    background: '#f2e6d5',
    primary: '#70543f',
    secondary: '#261206',
  },
  // {
  //   type: 'default',
  //   background: '#f3c2b9',
  //   primary: '#6e1e53',
  //   secondary: '#3a3462',
  // },
  // {
  //   type: 'neumorphism',
  //   background: '#dde3f0',
  //   primary: '#dde3f0',
  //   secondary: '#dde3f0',
  // },
];
