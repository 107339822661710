import React, { ButtonHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../hooks/theme';

import { Content, Foreground, Background } from './styles';

const Button: React.FC = () => {
  const { getTheme, changeTheme } = useTheme();
  const palette = getTheme();

  return (
    <Link to="/" onClick={changeTheme}>
      <Content palette={palette}>
        <Foreground palette={palette} className="foreground">
          <strong>Em breve</strong>
        </Foreground>
        <Background palette={palette} className="background">
          <span>Uma surpresa!</span>
        </Background>
      </Content>
    </Link>
  );
};

export default Button;
