import React from 'react';
import ParticlesTS from 'react-tsparticles';

import { useTheme } from '../../hooks/theme';

import { Style, GetParams } from '../../libs/Particles';

const Particles: React.FC = () => {
  const { getTheme } = useTheme();
  const palette = getTheme();

  return (
    <ParticlesTS
      params={GetParams(palette.primary)}
      style={Style}
      width="100vw"
      height="100vh"
    />
  );
};

export default Particles;
