import React, { createContext, useCallback, useContext, useState } from 'react';

import Palettes from '../utils/Palettes';

export interface Theme {
  type: string;
  background: string;
  primary: string;
  secondary: string;
}

interface ThemeContextData {
  changeTheme(): void;
  getTheme(): Theme;
}

const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData);

const ThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(() => {
    return Palettes[Math.floor(Math.random() * Palettes.length)] as Theme;
  });

  const changeTheme = useCallback(() => {
    let randomPalette;

    do {
      randomPalette = Palettes[Math.floor(Math.random() * Palettes.length)];
    } while (randomPalette === theme);

    setTheme(randomPalette);
  }, [theme]);

  const getTheme = useCallback(() => {
    return theme;
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ changeTheme, getTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

function useTheme(): ThemeContextData {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
}

export { ThemeProvider, useTheme };
