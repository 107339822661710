import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { useTheme } from '../hooks/theme';

import Main from '../pages/Main';

import GlobalStyle from '../styles/global';

const Routes: React.FC = () => {
  const { getTheme } = useTheme();
  const palette = getTheme();

  return (
    <>
      <Switch>
        <Route path="/" exact component={Main} />
      </Switch>

      <GlobalStyle palette={palette} />
    </>
  );
};

export default Routes;
