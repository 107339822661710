import React, { useEffect } from 'react';
import {
  FiAlertCircle,
  FiCheckCircle,
  FiInfo,
  FiXCircle,
} from 'react-icons/fi';
import { Transition } from 'react-spring/renderprops';

import { ToastMessage, useToast } from '../../../hooks/toast';

import { Container, Duration } from './styles';

interface ToastProps {
  message: ToastMessage;
  style: object;
}

const icons = {
  info: <FiInfo size={24} />,
  error: <FiAlertCircle size={24} />,
  success: <FiCheckCircle size={24} />,
};

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();
  const hasDuration = true;

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message.id]);

  return (
    <Container
      type={message.type}
      hasDescription={Number(!!message.description)}
      style={style}
    >
      {icons[message.type || 'info']}

      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>

      <button onClick={() => removeToast(message.id)} type="button">
        <FiXCircle size={18} />
      </button>

      <Transition
        items={hasDuration}
        from={{ right: '0%' }}
        enter={{ right: '100%' }}
        config={{ duration: 3000 }}
      >
        {
          (duration) =>
            duration &&
            ((props) => <Duration type={message.type} style={props} />)
          // eslint-disable-next-line react/jsx-curly-newline
        }
      </Transition>
    </Container>
  );
};

export default Toast;
