import styled from 'styled-components';

import { Theme } from '../../hooks/theme';

interface ThemeProps {
  palette: Theme;
}

export const Content = styled.div<ThemeProps>`
  width: 160px;
  height: 52px;
  border-radius: 32px;
  position: absolute;
  z-index: 1;

  top: 64px;
  right: 66px;

  overflow: hidden;

  /* ${(props) =>
    props.palette?.type === 'neumorphism'
      ? `background: linear-gradient(145deg, #c7ccd8, #ecf3ff);
          box-shadow:  11px 11px 30px #bcc1cc, -11px -11px 30px #feffff;`
      : ''} */

  &:hover .foreground {
    transform: translateX(-90%);
  }

  &:hover .background {
    transform: scale(1);
  }

  &:hover .background span {
    transform: translateY(0) scale(1);
  }

  @media screen and (max-width: 915px) {
    left: 16px;
    top: 16px;
    right: auto;
  }
`;

export const Foreground = styled.div<ThemeProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;

  transition: transform 0.25s;
  transition-timing-function: ease-in-out;
  background: ${(props) => props.palette?.secondary || '#ffCB05'};
  color: #fff;

  strong {
    font-size: 18px;
    letter-spacing: 1px;
  }
`;

export const Background = styled.div<ThemeProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;

  transform: scale(0.9);
  transition: transform 0.3s;
  transition-timing-function: ease-in-out;

  background: ${(props) => props.palette?.primary || '#000000'};
  color: ${(props) => props.palette?.secondary || '#ffCB05'};

  span {
    font-size: 16px;
    font-weight: bold;

    transform: translateY(-10px) scale(0.6);
    transition: transform 0.3s;
    transition-timing-function: ease-in-out;
  }
`;
