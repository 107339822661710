import { createGlobalStyle } from 'styled-components';

import { Theme } from '../hooks/theme';

interface ThemeProps {
  palette: Theme;
}

export default createGlobalStyle<ThemeProps>`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    min-height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased !important;
    background: ${(props) => props.palette.background}
  }

  body, input, button, textarea {
    color: #000;
    font-size: 14px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  button {
    cursor: pointer;
  }

  hr {
    border: none;
  }
`;
