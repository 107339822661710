import styled from 'styled-components';

import { Theme } from '../../hooks/theme';

interface ThemeProps {
  palette: Theme;
}

export const Container = styled.section<ThemeProps>`
  width: 100%;
  height: 100vh;
  min-height: 350px;
  max-height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.palette?.background || '#ffffff'};

  @media (max-width: 1200px) {
    padding: 0 16px;
  }

  @media (max-height: 350px) {
    position: relative;
  }
`;

export const Hero = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  z-index: 1;

  svg {
    width: 30vw !important;
    max-width: 700px !important;
    min-width: 280px !important;
    height: auto !important;
  }

  span {
    font-weight: bold;
    font-size: 22px;
    display: block;
    margin-top: 16px;
    color: ${(props) => props.palette?.primary || '#000000'};

    a {
      color: ${(props) => props.palette?.primary || '#000000'};
    }

    @media screen and (max-width: 1600px) {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 915px) {
    transform: translateY(-50%);
  }
`;

export const Footer = styled.footer<ThemeProps>`
  position: absolute;
  z-index: 1;
  padding: 0 64px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  bottom: 64px;
  font-size: 18px;

  span {
    color: ${(props) => props.palette?.primary || '#000000'};

    @media screen and (max-width: 915px) {
      margin-bottom: 16px;
    }
  }

  strong {
    color: ${(props) =>
      props.palette?.background.includes('f') ? '#000' : '#fff'};
  }

  @media screen and (max-width: 915px) {
    flex-direction: column;
    font-size: 16px;
    padding: 0 16px;
    bottom: 16px;
  }
`;
